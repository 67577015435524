import React from 'react';
import Button from '../components/Button';
import Callout from '../components/Callout';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quote from '../components/Quote';
import Section from '../components/Section';
import heroVideo from '../videos/scenario-lights.mp4';

const IndexPage = ({ data }) => (
  <Layout title="Home">
    <Hero mediaSource={heroVideo} mediaType="video/mp4" className="mb-0">
      <div className="home-hero__container">
        <Image
          className="home-hero__container__logo-img"
          filename="harmony-reverse-logo.svg"
        />
        <h3 className="home-hero__container__title">
          Every Voice is Instrumental
        </h3>
        <Image
          className="home-hero__container__hero-img"
          filename="home-img-hero.png"
          objectFit="contain"
        />
      </div>
    </Hero>
    <Container fullWidth noPadding id="vft">
      <Callout className="vft-callout" to="/career-profiles">
        <p className="vft-callout--big">
          NEW Career Profiles: Engage your students with career conversations
          with real-world professionals in the Music Industry!
        </p>
      </Callout>
    </Container>

    <Section noPadding className="home-intro">
      <Container fullWidth noPadding className="home-intro__container">
        <div className="home-intro__container__white-background"></div>
        <div className="home-intro__container__text">
          <h1>AMPLIFYING STEAM WITH THE COUNTRY MUSIC INDUSTRY</h1>
          <p className="large">
            Give students a backstage pass inside the world of Country Music to
            learn how STEAM skills help make the magic happen—both on and off
            the stage.
          </p>
        </div>
        <div className="home-intro__container__every-voice-img">
          <Image filename="home-img-intro.jpg" />
        </div>
        <div className="home-intro__container__every-voice-text">
          <h2>Every Voice Counts</h2>
          <p>
            Whether it’s a performer on-stage, a crew member behind-the-scenes,
            or an audience member cheering within the crowd, the Country Music
            industry thrives on the unique perspectives of diverse individuals
            coming together around a single purpose.
          </p>
          <p>
            Working in Harmony inspires students to follow their passion and
            embrace innovative thinking through dynamic collaboration with
            others.
          </p>
          <Button to="/about">OUR MISSION</Button>
        </div>
        <Image
          className="home-intro__container__gritar-img"
          filename="home-graphic-guitar.svg"
        />
      </Container>
    </Section>
    <Container fullWidth className="p-0">
      <Image filename="home-img-stage.png" className="home-stage-image" />
    </Container>
    <Section noPadding className="home-music-perspectives">
      <Container fullWidth className="bg-white pt-5">
        <h2 className="color-primary-blue">
          Beyond the Stage: Diverse Careers in Country Music
        </h2>
        <p className="large">
          You might think a career in Country Music is limited to singing or
          playing an instrument like the guitar or fiddle, but there’s actually
          an incredible diversity of creative careers in Country Music both on
          and off the stage.
        </p>
        <Row>
          <Column className="home-music-perspectives__column" size={4}>
            <Image
              filename="01-hmpg-thumb-careers-tvDirector.jpg"
              className="home-music-perspectives__img"
            />
            <h3 className="home-music-perspectives__column__header">
              Television Director
            </h3>
            <p className="home-music-perspectives__column__text">
              Responsible for the look and feel of a televised Country Music
              performance, this career requires great communication skills and
              creative vision.
            </p>
          </Column>
          <Column className="home-music-perspectives__column" size={4}>
            <Image
              filename="01-hmpg-thumb-careers-marketingExec.jpg"
              className="home-music-perspectives__img"
            />
            <h3 className="home-music-perspectives__column__header">
              Marketing Executive
            </h3>
            <p className="home-music-perspectives__column__text">
              Even the best Country acts need a “cheerleader.” Marketing
              executives help build excitement and spread the word about great
              music and performances.
            </p>
          </Column>
          <Column className="home-music-perspectives__column" size={4}>
            <Image
              filename="01-hmpg-thumb-careers-singerSongwriter.jpg"
              className="home-music-perspectives__img"
            />
            <h3 className="home-music-perspectives__column__header">
              Singer Songwriter
            </h3>
            <p className="home-music-perspectives__column__text">
              A Country Music singer songwriter uses creativity, emotional
              insight, and musical talent to make songs that touch peoples'
              hearts.
            </p>
          </Column>
        </Row>
        <div className="d-flex pb-5">
          <Button
            className="home-music-perspectives__button"
            to="/career-profiles"
          >
            Explore Career Profiles
          </Button>
        </div>
      </Container>
    </Section>
    <Section noPadding>
      <Container fullWidth noPadding className="home-riffing">
        <div className="home-riffing__texture-and-headphones">
          <Image
            className="home-riffing__texture-and-headphones__texture"
            filename="home-background-texture-blue.svg"
          />
          <Image
            className="home-riffing__texture-and-headphones__headphones"
            filename="home-graphic-headphones.svg"
          />
        </div>
        <div className="home-riffing__activities">
          <div className="home-riffing__activities__content">
            <h2 className="color-primary-blue">Exploring STEAM Activities</h2>
            <p className="large mb-2">
              Engaging Classroom and Family Activities.
            </p>
            <Row className="home-riffing__activities__content__row">
              <Column
                className="home-riffing__activities__content__row__column pr-1"
                size={6}
              >
                <Image filename="home-img-riffing-classroom.jpg" />
                <h3 className="color-primary-blue">Classroom Activities</h3>
                <p>
                  Students can engage in important STEAM skills and take on the
                  roles of Country Music professionals with these hands-on
                  activities spotlighting diverse Country Music careers.
                </p>
                <Button
                  className="home-riffing__activities__content__row__column__button"
                  to="/activities"
                >
                  CLASSROOM ACTIVITIES
                </Button>
              </Column>
              <Column
                className="home-riffing__activities__content__row__column pl-1"
                size={6}
              >
                <Image filename="home-img-riffing-family.jpg" />
                <h3 className="color-primary-blue">Family Activities</h3>
                <p>
                  Get families into the mix and encourage students to become
                  STEAM stars with fun activities that bring music to life in
                  the home.
                </p>
                <Button
                  className="home-riffing__activities__content__row__column__button"
                  to="/activities#family-activities"
                >
                  FAMILY ACTIVITIES
                </Button>
              </Column>
            </Row>
          </div>
        </div>
      </Container>
    </Section>
    <Section noPadding className="home-virtual-field-trip">
      <Container fullWidth className="home-virtual-field-trip__container">
        <Image
          className="home-virtual-field-trip__container__drummer-img"
          filename="home-graphic-drummer.svg"
        />
        <div className="home-virtual-field-trip__container__callout-img-div">
          <Image
            className="home-virtual-field-trip__container__callout-img-div__img"
            filename="callout-bg.svg"
          />
        </div>
        <div className="home-virtual-field-trip__container__content">
          <div className="home-virtual-field-trip__container__content__text">
            <h2 className="color-white">
              STEAM Careers Take the Spotlight Virtual Field Trip: Country
              Music's Biggest Night&trade;
            </h2>
            <p>
              Here’s your backstage pass to see Country Music like never before
              and go behind stage at the CMA Awards for a Virtual Field Trip
              digging in on how the biggest night in Country Music happens.
            </p>
          </div>
          <Button signUp to="/virtual-field-trip#vft">
            Watch Now
          </Button>
        </div>
      </Container>
    </Section>
    <Section noPadding className="home-about">
      <Container fullWidth className="home-about__container">
        <Image
          className="home-about__container__image"
          filename="home-img-about.jpg"
        />
        <div className="home-about__container__text-section">
          <h2>About the Program</h2>
          <p>
            Each individual part is crucial to overall harmony, and every
            student has unique abilities that can be used to create success in a
            STEAM-driven industry like Country Music.
          </p>
          <p>
            Empower a new generation of trailblazers and creative thinkers with
            standards-aligned resources designed to get students amped about the
            possibilities of STEAM.
          </p>
          <Button to="/about">LEARN MORE</Button>
        </div>
      </Container>
    </Section>
    <Quote
      text="Collaborative learning has been shown to not only develop higher-level thinking skills in students, but boost their confidence and self-esteem as well."
      source="National Education Association"
      backgroundImage="home-bg-quote.png"
      className="bg-white"
    />
  </Layout>
);

export default IndexPage;
